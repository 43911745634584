import React from "react"
import { graphql } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import JobFullWidth from "../components/JobFullWidth"

// Image
import NoContent from "../components/NoContent"
import ButtonTemplate from "../components/buttons/ButtonTemplate"

const Jobs = ({ data }) => {
  const jobs = data.allSanityJobOpenings.edges

  return (
    <Layout>
      <SEO title="Jobs" />
      <Hero
        englishTitle="Current Opportunities"
        syllabics="ᐊᓄᑭᐃᐧᓇᐣ"
        heroImage={null}
        bgClassName="bg-center"
      ></Hero>
      {jobs.length >= 1 ? (
        <div className="pt-4">
          {jobs.map((job, index) => {
            const colors = ["blue", "red", "yellow"]
            const {
              applicationLink,
              jobTitle,
              summary,
              slug,
              applicationDeadline,
            } = job.node
            return (
              <JobFullWidth
                jobTitle={jobTitle}
                summary={summary}
                color={colors[index % 3]}
                index={index}
                applicationLink={applicationLink}
                applicationDeadline={applicationDeadline}
                slug={slug.current}
              />
            )
          })}
        </div>
      ) : (
        <NoContent>
          <h2>Sorry, there are no open positions at the moment.</h2>
          <h2> Please check again!</h2>

          <ButtonTemplate
            to="/"
            text="Back to Home Page"
            className="text-white uppercase tracking-wide mt-8"
            index={1}
          />
        </NoContent>
      )}
    </Layout>
  )
}

export default Jobs

export const query = graphql`
  query JobOpenings {
    allSanityJobOpenings(
      sort: { fields: [weight, applicationDeadline], order: [ASC, ASC] }
    ) {
      edges {
        node {
          summary
          jobTitle
          applicationDeadline(formatString: "MMMM Do, YYYY")
          applicationLink
          slug {
            current
          }
        }
      }
    }
  }
`
