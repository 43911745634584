import React from "react"
import ButtonTemplate from "./buttons/ButtonTemplate"

const JobFullWidth = ({
  jobTitle = "Needs Title",
  summary = "Lorem Ipsum",
  index = 0,
  applicationDeadline = null,
  slug = "",
}) => {
  return (
    <section className="bg-gray-light mt-2 pb-12">
      <div className="text-center">
        <h3 className="font-heading text-center mb-6 pt-8 text-4xl">
          {jobTitle}
        </h3>
        <p className="-mt-6 mb-10">
          <span className=" font-bold">Application deadline:</span>{" "}
          {applicationDeadline || (
            <span className="italic">Open until filled</span>
          )}
        </p>
      </div>
      <div className="flex flex-col max-w-3xl justify-center mx-auto">
        <p className="mx-4 text-md leading-tight mb-6">{summary}</p>
        <div className="flex">
          <div className="mx-auto">
            <ButtonTemplate
              to={`/jobs/${slug}`}
              text={`Learn More`}
              className="text-white uppercase  tracking-wide  "
              index={index}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
export default JobFullWidth
